<template>
  <div
    class="property-listing-card"
    @click="$emit('click')"
  >
    <img
      class="property-listing-card__image"
      :src="
        property.photos && property.photos[0]
          ? `https://storage.googleapis.com/bk-firstfloor-properties/${property._id}/photos/${property.photos[0]}`
          : '../../../assets/imgs/property_placeholder.jpg'
      "
    >
    <div class="property-listing-card__details">
      <div class="property-listing-card__details-title">
        <span class="property-listing-card__title-text">
          {{ property.title }}
        </span>
        <span class="property-listing-card__grey-text">
          {{ `${property.district}, ${property.city}` }}
        </span>
      </div>
      <div class="property-listing-card__details-subdetails">
        <span class="property-listing-card__title-text">
          {{ `${numberToDotNotationString(property.value)}€` }}
        </span>
        <div class="icons">
          <div class="icon">
            <span class="mdi mdi-bed-outline" />
            <span>{{ Number(property.typology.slice(1)) }}</span>
          </div>
          <div class="icon">
            <span class="mdi mdi-shower" />
            <span>{{ property.wcs }}</span>
          </div>
          <div class="icon">
            <span class="mdi mdi-car-outline" />
            <span>{{ property.garageSpaces }}</span>
          </div>
        </div>
      </div>
      <!-- TODO <div class="property-listing-card__right">
        <div
          class="notification-button"
          v-if="property.notifications"
        >
          <span> 2 </span>
        </div>
        <BaseButton
          label="Ativar"
          @click="$emit('click')"
        />
      </div> -->
    </div>
  </div>
</template>
<script>
// import { BaseButton } from 'ff-components-lib';
import { numberToDotNotationString } from '@/utils';

export default {
  name: 'PropertyListingCard',
  components: {
    // BaseButton,
  },
  props: {
    property: {
      type: Object,
      default: () => {},
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    numberToDotNotationString,
  },
};
</script>
<style lang="scss" scoped>
.property-listing-card {
  display: flex;
  padding: 16px 24px;
  gap: 24px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba($primary, 0.1);
  border-radius: 8px;

  cursor: pointer;

  &__image {
    width: 106px;
    height: 80px;
    object-fit: cover;
  }

  &__details {
    display: flex;
    width: 100%;

    &-title {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      gap: 8px;
    }

    &-subdetails {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__title-text {
    font-size: 18px;
    font-weight: 600;
  }

  &__grey-text {
    font-size: 12px;
    font-weight: 600;
    color: $grey;
  }

  .icons {
    display: flex;
    gap: 16px;
    color: $grey;
    font-size: 12px;
    font-weight: 600;

    .icon {
      display: flex;
      gap: 8px;

      .mdi {
        font-size: 18px;
        height: 18px;
        display: flex;
        align-items: center;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 24px;
    width: 90px;
  }

  .notification-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 24px;
    height: 24px;
    border-radius: 50%;

    background-color: red;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 770px) {
  .property-listing-card {
    padding: 12px;
    gap: 12px;

    &__image {
      width: 73px;
      height: auto;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &-title {
        display: flex;
        flex-direction: column;
        margin-right: auto;
        gap: 6px;
      }

      &-subdetails {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;
      }
    }

    &__title-text {
      font-size: 14px;
    }

    &__grey-text {
      font-size: 10px;
    }

    .icons {
      gap: 10px;
      font-size: 10px;

      .icon {
        display: flex;
        gap: 4px;

        .mdi {
          font-size: 16px;
          height: 16px;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-left: 24px;
      width: 90px;
    }
  }
}
</style>
