<template>
  <InternalLayout>
    <template slot="body">
      <div class="margin-container">
        <Tabs
          name="listings-tabs"
          :tabs="$t('sections.listings.tabs')"
          :active-tab="activeTab"
          @change="handleTabsChange"
        />

        <div class="listings">
          <PropertyListingCard
            v-for="(listing, index) in getProperties"
            :key="`listing-card-${index}`"
            :property="listing"
            @click="
              $router.push({
                name: 'dashboard-listing-detail',
                params: {
                  id: listing._id,
                },
              })
            "
          />
        </div>
      </div>
    </template>
  </InternalLayout>
</template>
<script>
import { mapGetters } from 'vuex';
import { Tabs } from 'ff-components-lib';

import InternalLayout from '@/components/layout/internal/InternalLayout';
import PropertyListingCard from '@/components/ui/PropertyListingCard';

export default {
  name: 'ListingsScreen',
  components: {
    InternalLayout,
    Tabs,
    PropertyListingCard,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters(['getBreakpoints', 'getProperties', 'getUser']),
  },
  async created() {
    this.$store.dispatch('storeLoading', true);
    await this.$store.dispatch('storeProperties', {
      query: { email: this.getUser.email },
    });
    this.$store.dispatch('storeLoading', false);
  },
  methods: {
    handleTabsChange(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  margin-bottom: 74px;
}

.listings {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
